<div class="container">
  <div class="content" fxLayout="row" fxLayout.xs="column" fxLayoutGap="50px" fxFlexFill>
    <div fxFlex="15" class="sec1" fxFlex.xs="55">
      <app-sidenav fxFlex fxShow="true" fxHide.lt-md="true"></app-sidenav>
    </div>
    <div class="sec1">
      <h1 class="ztextcenter">SAP S4Hana</h1>   
      <section>
        <!-- <h2>WWF History</h2> -->
        <p class="beginPara">
          <b>SAP HANA</b>, The power of in memory computing that speeds up real-time decisions and actions.
          <br>
          In memory computations of HANA is harnessed only when we use the database in a better way to get best
          performance.
          <br><br>
          Data models are the keys aspect of any application. Data Models are created using CDS(Core data services).
          With CDS, data models are defined and consumed on database server rather than on application server.
          With CDS, the code execution is pushed down to the database.
          <br><br>
          With SAP NW ABAP 7.4 SP5, New modelling <b>Core Data Services (CDS)</b> was introduced & it continues to evolve with versions.
          <br>
          <b>Why CDS?</b> Code Push-Down
          <ul>
            <li>Code pushdown means delegating data intense calculations to the database layer.</li>
            <li>Do as much as possible in Database to get best performance and leverage database.
            </li>
          </ul>
          <b>How to create CDS?</b>
          <p>A CDS entity is defined in the text-based DDL editor of ABAP Development Tools in Eclipse/SAP HANA studio.          
          <p><b>Availability</b> The Core Data services are available in below mentioned SAP Platforms:
           <ul>
            <li>SAP NetWeaver 7.50, SP01, or higher.</li>
            <li>SAP NetWeaver 7.4 SP05.</li>
            <li>SAP HANA SPS6.</li>
            <li>SAP Business Suite EHP7</li>
            <li>S/4HANA </li>
            <li>SAP Business Warehouse 7.3</li>
           </ul>
          </section>

      <section>
        <h3>VDM</h3>        
        <p>The concept of Virtual Data Model (VDM) was introduced with HANA Live few years ago, SAP HANA Live is a
          Virtual data model on top of suite tables which uses native SAP HANA SQL views called Calculation views for
          real-time operational Reporting.</p>
      </section>
    </div>
    <div class="sec1"></div>
  </div>
  <!-- <div class="pageCenter">
  </div> -->
</div>
