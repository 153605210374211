import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './general/home/home.component';
import { PagenotfoundComponent } from './reuse/pagenotfound/pagenotfound.component';
import { Cds751sp00Component } from './pages/cds751sp00/cds751sp00.component';
import { ComingsoonComponent } from './reuse/comingsoon/comingsoon.component';


const routes: Routes = [
     { path: '', component: HomeComponent },
    //Latest version should have path as cds only.  
     { path: 'cds' , component: Cds751sp00Component},
     { path: 'comingsoon' , component: ComingsoonComponent},
     { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
