import { Component, OnInit } from "@angular/core";
import { BottomsheetComponent } from "../bottomsheet/bottomsheet.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent implements OnInit {
  shareCheck: any;
  defaultShare = ["facebook", "twitter", "watsapp", "telegram"];

  constructor(
    public snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet
  ) {}

  ngOnInit() {}

  showShare() {
    let ssUrl = window.location.href;
    this.shareCheck = window.navigator;
    if (this.shareCheck && this.shareCheck.share) {
      this.shareCheck
        .share({
          title: "ABAP CDS",
          text: "SAP S4hana ABAP CDS",
          url: ssUrl
        })
        .then(
          () => {
            this.snackBar.open("Share Successfully", "Ok", {
              duration: 2500
            });
          },
          (error:any) => {
            this.snackBar.open("Share Cancelled", "Ok", {
              duration: 2500
            });
          }
        );
    } else {
      this.browserShare(this.defaultShare);
    }
  }

  browserShare(data: any) {
    let browserShareUrl;
    let sheet = this.bottomSheet.open(BottomsheetComponent, {
      data: data
    });
    sheet.afterDismissed().subscribe(data => {
      browserShareUrl = "";
      switch (data) {
        case "facebook":
          browserShareUrl = "https://www.facebook.com/sharer/sharer.php?u=https://www.s4hana.in";
          break;
        case "twitter":
          browserShareUrl = "https://twitter.com/intent/tweet?text=https://www.s4hana.in";
          break;
        case "watsapp":
          browserShareUrl = "https://wa.me/?text=https://www.s4hana.in";
          break;
        case "telegram":
          browserShareUrl = "https://telegram.me/share/url?url=https://www.s4hana.in&text=SAP S4hana ABAP CDS";  
          break;
        default:
          break;
      }
      if (data) {        
        let dataUrlIn = browserShareUrl;
        window.open(dataUrlIn, data, "width=500,height=500");
      }
    });
  }

  email() {
    window.open('mailto:s4hana.in@gmail.com');
  }
}
