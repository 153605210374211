import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(private http: HttpClient,
              private meta: Meta,
              private title: Title) { }

  getModules(){
    let url = "https://s4hana-22241.firebaseio.com/data.json";
    return this.http.get(url);
  }

  getTables() {
    return this.http.get('../../assets/json/tables.json');
  }

  getTablesFieldsList() {
    return this.http.get('../../assets/json/tables.json');
  }

  gaPageView(pageTitle: string, pagePath: string) {
    gtag('config', 'UA-153485485-1', {
      'page_title': pageTitle,
      'page_path': pagePath
    });
  }

  setTitle(pageTitle: string) {
    this.title.setTitle(pageTitle);
  }

  updateMetaTag(tagConfig: any) {    
    tagConfig.homePageUrl = 'https://www.s4hana.in';
    //page Title
    this.setTitle(tagConfig.title);
    //Regular Meta
    this.meta.updateTag({ name: 'title', content: tagConfig.title });
    this.meta.updateTag({ name: 'Description', content: tagConfig.description });
    this.meta.updateTag({ name: 'keywords', content: tagConfig.keywords });

    //twitter Meta
    this.meta.updateTag({ name: 'twitter:card', content: 'app' });
    this.meta.updateTag({ name: 'twitter:site', content: tagConfig.content });
    this.meta.updateTag({ name: 'twitter:title', content: tagConfig.title });
    this.meta.updateTag({ name: 'twitter:description', content: tagConfig.description });
    this.meta.updateTag({ name: 'twitter:url', content: tagConfig.pageUrl });
    this.meta.updateTag({ name: 'twitter:image', content: tagConfig.image });

    //Facebook Meta
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:site_name', content: 'https://www.s4hana.in' });
    this.meta.updateTag({ property: 'og:title', content: tagConfig.title });
    this.meta.updateTag({ property: 'og:description', content: tagConfig.description });
    this.meta.updateTag({ property: 'og:url', content: tagConfig.pageUrl });
    this.meta.updateTag({ property: 'og:image', content: tagConfig.image });
    //this.meta.updateTag({ property: 'og:see_also', content: tagConfig.homePageUrl });
  }

  defaultTags() {
    let tagConfig = {
      title: 'SAP S4hana ABAP CDS Code Learning',
      description: 'SAP S4hana ABAP CDS code Simplified, Learn ABAP CDS',
      keywords: 'SAP S4hana ABAP CDS Code Learning',
      content: 'SAP S4hana ABAP CDS code Simplified, Learn ABAP CDS',
      pageUrl: 'https://www.s4hana.in',
      //image: '',
    };
    return tagConfig;
  }

}
 