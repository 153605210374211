<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1>
          Oops!</h1>
        <h2>
          404 Not Found</h2>
        <div class="error-details">
          Sorry, an error has occured, Requested page not found!
        </div>
        <div class="error-actions">
          <button mat-stroked-button color="primary" routerLink="">
            <mat-icon class="iconTextAlign">home</mat-icon>Take Me Home
          </button>
          <span style="padding-right: 5px;"></span>
          <button mat-stroked-button color="accent" (click)="email()">
            <mat-icon class="iconTextAlign">mail</mat-icon>Contact Support
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
