<div class="container">
  <div class="topbar" fxLayout="row">
    <button mat-raised-button color="accent" class="ButtonCornerLeft" aria-label="Reset" (click)="resetCode()">
      Reset
    </button>
    <span class="zspace"></span>
    <h2 class="mat-h2 ztextcenter">CDS ABAP 7.51 SP 00</h2>
    <span class="zspace"></span>
    <!-- <app-cdsversion class="selectCss"></app-cdsversion> -->
    <span class="zspace"></span>
    <button mat-raised-button color="accent" class="copyButtonCorner" aria-label="Copy" (click)="copyCode(textarea)">
      Copy
    </button>
  </div>
  <div class="content" fxLayout="row" fxLayout.xs="column" fxFlexFill>
    <div fxFlex="52" class="sec1" fxFlex.xs="55">
      <form [formGroup]="cdsForm01">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px"
          fxLayoutGap.xs="10px">
          <mat-accordion class="inputFullWidth">
            <mat-expansion-panel expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Names &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                </mat-panel-title>
                <mat-panel-description>
                  Mandatory
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-form-field appearance="legacy" class="inputFullWidth">
                <input matInput matTooltip="Database view in ABAP dictionary, SE11" placeholder="Name"
                  formControlName="name" maxlength="16" #name required>
                <mat-hint *ngIf="!nameErr">Database view in ABAP dictionary, SE11</mat-hint>
                <mat-hint class="hintErr" *ngIf="nameErr">{{nameErr}}</mat-hint>
                <mat-hint align="end">{{name.value.length}} / 16</mat-hint>
              </mat-form-field>
              <mat-form-field appearance="legacy" class="inputFullWidth">
                <mat-label>Label</mat-label>
                <input matInput matTooltip="Texts, short text connected to translation" placeholder="Label"
                  formControlName="label" maxlength="60" #label required>
                <mat-hint align="end">{{label.value.length}} / 60</mat-hint>
              </mat-form-field>
              <mat-form-field appearance="legacy" class="inputFullWidth">
                <mat-label>Quick Info</mat-label>
                <input matInput matTooltip="Texts, short text connected to translation" placeholder="Quick Info"
                  formControlName="quickInfo" maxlength="60" #quickInfo required>
                <mat-hint align="end">{{quickInfo.value.length}} / 60</mat-hint>
              </mat-form-field>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Buffer &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                </mat-panel-title>
                <mat-panel-description>
                  Optional
                </mat-panel-description>
              </mat-expansion-panel-header>
              <mat-form-field appearance="legacy" class="inputFullWidth">
                <mat-label>Buffer Status</mat-label>
                <mat-select matTooltip="Table buffering, enables and disables buffering" formControlName="buffStatus">
                  <mat-option value="#ACTIVE">Active</mat-option>
                  <mat-option value="#NOT_ALLOWED">Not Allowed</mat-option>
                  <mat-option value="#SWITCHED_OFF">Switched Off(Default)</mat-option>
                  <mat-option value="#FULL">Full</mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="(cdsForm01.value.buffStatus === '#ACTIVE' || cdsForm01.value.buffStatus === '#FULL')">
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <mat-label>Buffer Type</mat-label>
                  <mat-select matTooltip="Table buffering, defines the buffering type" formControlName="buffType">
                    <mat-option value="#FULL">Full</mat-option>
                    <mat-option value="#GENERIC">Generic</mat-option>
                    <mat-option value="#NONE">None(Default)</mat-option>
                    <mat-option value="#SINGLE">Single</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="inputFullWidth" *ngIf="cdsForm01.value.buffType != '#NONE'">
                  <mat-label>Buffer No. of Keys</mat-label>
                  <input matInput type="number"
                    matTooltip="Number between 0 and k-1, where k is the number of key elements	"
                    placeholder="Buffer No. of Keys" formControlName="buffNumKeys" min=1>
                </mat-form-field>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Others &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                </mat-panel-title>
                <mat-panel-description>
                  Optionals
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                fxLayoutGap="10px" fxLayoutGap.xs="10px">
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <mat-label>Authorization Check</mat-label>
                  <mat-select matTooltip="CDS access control, specifies implicit access control"
                    formControlName="authorizationCheck">
                    <mat-option value="#CHECK">Check(Default)</mat-option>
                    <mat-option value="#NOT_ALLOWED">Not_Allowed</mat-option>
                    <mat-option value="#NOT_REQUIRED">Not_Required</mat-option>
                    <mat-option value="#PRIVILEGED_ONLY">Privileged_Only</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <mat-label>Compare Filter</mat-label>
                  <mat-select matTooltip="CDS view, specifies how filter conditions are evaluated in path expressions"
                    formControlName="compareFilter">
                    <mat-option value="True">True(Default)</mat-option>
                    <mat-option value="False">False</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                fxLayoutGap="10px" fxLayoutGap.xs="10px">
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <mat-label>Client Handling Algorithm</mat-label>
                  <mat-select matTooltip="Specifies client handling" formControlName="clientHandlingAlgorithm">
                    <mat-option value="#AUTOMATED">Automated</mat-option>
                    <mat-option value="#NONE">None</mat-option>
                    <mat-option value="#SESSION_VARIABLE">Session Variable</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <mat-label>Client Handling Type</mat-label>
                  <mat-select matTooltip="Specifies client dependency" formControlName="clientHandlingType">
                    <mat-option value="#CLIENT_DEPENDENT">Client Dependent</mat-option>
                    <mat-option value="#CLIENT_INDEPENDENT">Client Independent</mat-option>
                    <mat-option value="#INHERITED">Inherited(Default)</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Definition &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                </mat-panel-title>
                <mat-panel-description>
                  Mandatory
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                fxLayoutGap="10px" fxLayoutGap.xs="10px">
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <input matInput matTooltip="CDS Entity Name" placeholder="CDS entity Name"
                    formControlName="defineView" required>
                  <mat-hint class="hintErr" *ngIf="defineViewErr">{{defineViewErr}}</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <input matInput matTooltip="SAP Table" placeholder="Table" formControlName="tableName" required>
                  <mat-hint class="hintErr" *ngIf="tableNameErr">{{tableNameErr}}</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <input matInput matTooltip="SAP Table as" placeholder="As" formControlName="tableNameAs">
                  <mat-hint class="hintErr" *ngIf="tableNameAsErr">{{tableNameAsErr}}</mat-hint>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                fxLayoutGap="10px" fxLayoutGap.xs="10px" class="padd10px">
                <mat-radio-group aria-label="Select the Join" formControlName="withJoin">
                  <mat-radio-button value="INNER JOIN">Inner Join&emsp;</mat-radio-button>
                  <mat-radio-button value="LEFT OUTER JOIN">Left Outer Join&emsp;</mat-radio-button>
                  <mat-radio-button value="RIGHT OUTER JOIN">Right Outer Join&emsp;</mat-radio-button>
                  <mat-radio-button value="">None</mat-radio-button>
                </mat-radio-group>
              </div>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                fxLayoutGap="10px" fxLayoutGap.xs="10px" *ngIf="joinBool">
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <input matInput matTooltip="Join Table" placeholder="Join Table" formControlName="joinTableName"
                    required>
                  <mat-hint class="hintErr" *ngIf="joinTableNameErr">{{joinTableNameErr}}</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <input matInput matTooltip="Join Table" placeholder="Join Field" formControlName="joinJoinField"
                    required>
                  <mat-hint class="hintErr" *ngIf="joinJoinFieldErr">{{joinJoinFieldErr}}</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="legacy" class="inputFullWidth">
                  <input matInput matTooltip="Join Table" placeholder="Source Field" formControlName="joinSourceField"
                    required>
                  <mat-hint class="hintErr" *ngIf="joinSourceFieldErr">{{joinSourceFieldErr}}</mat-hint>
                </mat-form-field>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Parameters &#160;&#160;&#160;
                </mat-panel-title>
                <mat-panel-description>
                  Optional
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                fxLayoutGap="10px" fxLayoutGap.xs="10px" class="padd10px">
                <mat-checkbox matTooltip="Enable Parameters" formControlName="withParameters">Parameters</mat-checkbox>
              </div>
              <form [formGroup]="parameterForm" *ngIf="cdsForm01.value.withParameters">
                <p class="ztextcenter">Add Parameters</p>
                <mat-table [dataSource]="parameterDataSource" formArrayName="parameterArr">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
                      <input matInput type="text" placeholder="Type here.." formControlName="name">
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
                      <input matInput type="text" placeholder="Type here.." formControlName="type">
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="buttons">
                    <mat-header-cell *matHeaderCellDef>
                      <button mat-icon-button color="accent" aria-label="Add Rows" (click)="addPRow()">
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
                      <button mat-icon-button color="warn" aria-label="Remove Rows" (click)="removePRow(i)">
                        <mat-icon>delete_forever</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="parameterColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; let i = index; columns: parameterColumns;"></mat-row>
                </mat-table>
              </form>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Associations &#160;&#160;
                </mat-panel-title>
                <mat-panel-description>
                  Optional
                </mat-panel-description>
              </mat-expansion-panel-header>
              <p>Coming Soon....!</p>
              <!-- <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                  fxLayoutGap="10px" fxLayoutGap.xs="10px">
                    <mat-form-field appearance="legacy" class="inputFullWidth">
                      <mat-label>Cardinality</mat-label>
                      <mat-select matTooltip=""
                        formControlName="authorizationCheck">
                        <mat-option value="[0..1]">[0..1]</mat-option>
                        <mat-option value="[0..*]">[0..*]</mat-option>
                        <mat-option value="[1..*]">[1..*]</mat-option>
                        <mat-option value="[1..1]">[1..1]</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <p> to </p>              
                    <mat-form-field appearance="legacy" class="inputFullWidth">
                      <input matInput matTooltip="Table" placeholder="Join Field" formControlName="authorizationCheck">
                    </mat-form-field>
                    <p> as </p>
                    <mat-form-field appearance="legacy" class="inputFullWidth">
                      <input matInput matTooltip="Join Table" placeholder="Source Field" formControlName="authorizationCheck">
                    </mat-form-field>
                  </div>
                  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                  fxLayoutGap="10px" fxLayoutGap.xs="10px">
                  <p> on </p>
                  <mat-form-field appearance="legacy" class="inputFullWidth">
                    <input matInput matTooltip="Join Table" placeholder="Source Field" formControlName="authorizationCheck">
                  </mat-form-field>
                  <p> = </p>
                    <mat-form-field appearance="legacy" class="inputFullWidth">
                      <input matInput matTooltip="Join Table" placeholder="Source Field" formControlName="authorizationCheck">
                    </mat-form-field>
                  </div> -->
            </mat-expansion-panel>
            <mat-expansion-panel #matExpansionPanel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Table Fields &#160;&#160;&#160;&#160;&#160;
                </mat-panel-title>
                <mat-panel-description>
                  Mandatory
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="hintErr" *ngIf="!cdsTableName">Definition/Table Name is missing</div>
              <form [formGroup]="tableForm" *ngIf="cdsTableName">
                <mat-table [dataSource]="tableDataSource" formArrayName="tableArr">
                  <ng-container matColumnDef="field">
                    <mat-header-cell *matHeaderCellDef> Field </mat-header-cell>
                    <mat-cell *matCellDef="let tableRow; let i = index;" [formGroupName]="i">
                      <input matInput type="text" placeholder="Type here.." formControlName="field">
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="key">
                    <mat-header-cell *matHeaderCellDef> Key </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
                      <mat-checkbox formControlName="key"></mat-checkbox>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="aggfunctions">
                    <mat-header-cell *matHeaderCellDef> Aggregate Functions </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i" class="dropdown">
                      <mat-select formControlName="aggfunctions" class="selectSmall"
                        (selectionChange)="aggfunctionselect($event, i)">
                        <mat-option value="max ">Max</mat-option>
                        <mat-option value="min ">Min</mat-option>
                        <mat-option value="avg ">Avg</mat-option>
                        <mat-option value="sum ">Sum</mat-option>
                        <mat-option value="count ">Count</mat-option>
                        <mat-option value="length ">Count</mat-option>
                        <mat-option value=""> </mat-option>
                      </mat-select>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="numfunctions">
                    <mat-header-cell *matHeaderCellDef> Numeric Functions </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i" class="dropdown">
                      <mat-select formControlName="numfunctions" class="selectSmall"
                        (selectionChange)="numfunctionselect($event, i)">
                        <mat-option value="abs  ">Abs</mat-option>
                        <mat-option value="ceil ">Ceil</mat-option>
                        <mat-option value="floor">Floor</mat-option>
                        <mat-option value="div">Div</mat-option>
                        <mat-option value="division">Division</mat-option>
                        <mat-option value="mod">Mod</mat-option>
                        <mat-option value="round">Round</mat-option>
                        <mat-option value="fltp_to_dec">FLTP_TO_DEC</mat-option>
                        <mat-option value=""> </mat-option>
                      </mat-select>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="fieldas">
                    <mat-header-cell *matHeaderCellDef>As</mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
                      <input matInput type="text" placeholder="Type here.." formControlName="fieldas">
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="buttons">
                    <mat-header-cell *matHeaderCellDef>
                      <button mat-icon-button color="accent" aria-label="Add Rows" (click)="addRow()">
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let tableRow; let i = index;" [formGroupName]="i">
                      <button mat-icon-button color="warn" aria-label="Remove Rows" (click)="removeRow(i)">
                        <mat-icon>delete_forever</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container> -->
                  <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                  <mat-row *matRowDef="let tableRow; let i = index; columns: tableColumns;"></mat-row>
                </mat-table>
              </form>
              <app-tablestrfunc [parentForm]="cdsForm01" (stringFunctionArr)="stringFunctionArr($event)"
                *ngIf="cdsTableName">
              </app-tablestrfunc>
              <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column"
                fxLayoutGap="10px" fxLayoutGap.xs="10px" class="padd10px">
                <mat-checkbox matTooltip="Enable Session Variables" formControlName="withSessionVariables"
                  *ngIf="cdsTableName">Add Session
                  Variables</mat-checkbox>
              </div>
              <form [formGroup]="sessionVariablesForm" *ngIf="cdsForm01.value.withSessionVariables">
                <mat-table [dataSource]="sessionVariablesDataSource" formArrayName="sessionVariablesArr">
                  <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
                      <mat-select formControlName="name">
                        <mat-option value="user &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;">User</mat-option>
                        <mat-option value="client &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;">Client</mat-option>
                        <mat-option value="system_language &nbsp;">System Language</mat-option>
                        <mat-option value="system_date &emsp;&emsp;&emsp;&emsp;">System Date</mat-option>
                      </mat-select>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="fieldas">
                    <mat-header-cell *matHeaderCellDef> Field As </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
                      <input matInput type="text" placeholder="Type here.." formControlName="fieldas">
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="buttons">
                    <mat-header-cell *matHeaderCellDef>
                      <button mat-icon-button color="accent" aria-label="Add Rows" (click)="addSessionVariablesRow()">
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
                      <button mat-icon-button color="warn" aria-label="Remove Rows"
                        (click)="removeSessionVariablesRow(i)">
                        <mat-icon>delete_forever</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="sessionVariablesColumns"></mat-header-row>
                  <mat-row *matRowDef="let row; let i = index; columns: sessionVariablesColumns;"></mat-row>
                </mat-table>
              </form>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Where Clause
                </mat-panel-title>
                <mat-panel-description>
                  Optional
                </mat-panel-description>
              </mat-expansion-panel-header>
              <form [formGroup]="whereForm">
                <mat-table [dataSource]="wdataSource" formArrayName="whereArr">
                  <ng-container matColumnDef="wfield">
                    <mat-header-cell *matHeaderCellDef> Field </mat-header-cell>
                    <mat-cell *matCellDef="let wrow; let i = index;" [formGroupName]="i">
                      <input matInput type="text" placeholder="Type here.." formControlName="wfield">
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="operator">
                    <mat-header-cell *matHeaderCellDef> Operator </mat-header-cell>
                    <mat-cell *matCellDef="let wrow; let i = index;" [formGroupName]="i" class="dropdown">
                      <mat-select formControlName="operator" class="selectSmall selectSize">
                        <mat-option value="=">=</mat-option>
                        <mat-option value=">">></mat-option>
                        <mat-option value="<">
                          <</mat-option> <mat-option value="<>">
                            <>
                        </mat-option>
                        <mat-option value=">=">>=</mat-option>
                        <mat-option value="<=">
                          <=</mat-option> </mat-select> </mat-cell> </ng-container> <ng-container matColumnDef="fvalue">
                            <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
                            <mat-cell *matCellDef="let wrow; let i = index;" [formGroupName]="i">
                              <input matInput type="text" placeholder="Type here.." formControlName="fvalue">
                            </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="condopr">
                    <mat-header-cell *matHeaderCellDef>Condition</mat-header-cell>
                    <mat-cell *matCellDef="let wrow; let i = index;" [formGroupName]="i" class="dropdown">
                      <mat-select formControlName="condopr" class="selectSmall">
                        <mat-option value="and">And</mat-option>
                        <mat-option value="or">Or</mat-option>
                        <mat-option value=""></mat-option>
                      </mat-select>
                    </mat-cell>
                  </ng-container>
                  <ng-container matColumnDef="wbuttons">
                    <mat-header-cell *matHeaderCellDef>
                      <button mat-icon-button color="accent" aria-label="Add Rows" (click)="addWRow()">
                        <mat-icon>add_circle</mat-icon>
                      </button>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let wrow; let i = index;" [formGroupName]="i">
                      <button mat-icon-button color="warn" aria-label="Remove Rows" (click)="removeWRow(i)">
                        <mat-icon>delete_forever</mat-icon>
                      </button>
                    </mat-cell>
                  </ng-container>
                  <mat-header-row *matHeaderRowDef="whereColumns"></mat-header-row>
                  <mat-row *matRowDef="let wrow; let i = index; columns: whereColumns;"></mat-row>
                </mat-table>
              </form>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  OData &#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;
                </mat-panel-title>
                <mat-panel-description>
                  Optional
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="ztextcenter">
                <mat-checkbox matTooltip="Enable OData" formControlName="withOData">Enable OData</mat-checkbox>
                <div class="note"> Mandatory atleast one key in Table Fields </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </form>
    </div>
    <div fxFlex="48" class="sec2">
      <div #textarea class="textAreaDiv">
        <p class="comments">/* This is your code */</p>
        <p *ngIf="cdsForm01.value.name"><span class="keyCode">@AbapCatalog.sqlViewName:
          </span>'{{cdsForm01.value.name}}'</p>
        <p *ngIf="cdsForm01.value.label"><span class="keyCode">@EndUserText.label: </span>'{{cdsForm01.value.label}}'
        </p>
        <p *ngIf="cdsForm01.value.quickInfo">
          <span class="keyCode">@EndUserText.quickInfo:</span>'{{cdsForm01.value.quickInfo}}'</p>
        <p *ngIf="cdsForm01.value.buffStatus">
          <span class="keyCode">@AbapCatalog.buffering.status:</span>{{cdsForm01.value.buffStatus}}</p>
        <div *ngIf="(cdsForm01.value.buffStatus === '#ACTIVE' || cdsForm01.value.buffStatus === '#FULL')">
          <p *ngIf="cdsForm01.value.buffType">
            <span class="keyCode">@AbapCatalog.buffering.type:</span>{{cdsForm01.value.buffType}}</p>
          <p *ngIf="cdsForm01.value.buffNumKeys"><span
              class="keyCode">@AbapCatalog.buffering.numberOfKeyFields:</span>{{ cdsForm01.value.buffNumKeys}}</p>
        </div>
        <p *ngIf="cdsForm01.value.authorizationCheck">
          <span class="keyCode">@AccessControl.authorizationCheck:</span>{{cdsForm01.value.authorizationCheck}}</p>
        <p *ngIf="cdsForm01.value.compareFilter">
          <span class="keyCode">@AbapCatalog.complier.compareFilter:</span>{{cdsForm01.value.compareFilter}}</p>
        <p *ngIf="cdsForm01.value.clientHandlingAlgorithm">
          <span class="keyCode">@ClientHandling.algorithm: </span>{{cdsForm01.value.clientHandlingAlgorithm}}</p>
        <p *ngIf="cdsForm01.value.clientHandlingType">
          <span class="keyCode">@ClientHandling.type:</span>{{cdsForm01.value.clientHandlingType}}</p>
        <p *ngIf="cdsForm01.value.withOData">
          <span class="keyCode">@OData.publish: </span> {{cdsForm01.value.withOData}}</p>
        <p *ngIf="cdsForm01.value.defineView"><span class="keyCode">Define View </span>{{cdsForm01.value.defineView}}
        </p>
        <p *ngIf="cdsForm01.value.withParameters"><span class="keyCode tab1">with parameters</span>
          {{cdsWithParameters}} {{cdsParameters}}
        </p>
        <p *ngIf="cdsForm01.value.tableName"><span class="keyCode tab1"> As select from
          </span>{{cdsForm01.value.tableName}}<span *ngIf="cdsForm01.value.tableNameAs"><span class="keyCode"> as
            </span>{{cdsForm01.value.tableNameAs}}</span></p>
        <p *ngIf="cdsForm01.value.withJoin"><span class="keyCode tab1">{{cdsForm01.value.withJoin}}</span>
          {{ cdsForm01.value.joinTableName}}</p>
        <p *ngIf="cdsForm01.value.joinTableName"><span class="keyCode tab1">On</span>
          {{ cdsForm01.value.joinTableName}}.{{cdsForm01.value.joinJoinField}} =
          {{cdsForm01.value.tableName}}.{{cdsForm01.value.joinSourceField}} </p>
        <p *ngIf="cdsFields" class="tab1">&#123;</p>
        <p class="tab2"
          *ngFor="let tableRow of tableForm.value.tableArr; let i = index; first as isFirst; last as isLast">
          <span *ngIf="tableRow.aggfunctions && tableRow.field">
            <span *ngIf="tableRow.aggfunctions"><span class="keyCode">{{tableRow.aggfunctions}}</span>( </span>
            <span *ngIf="tableRow.key" class="keyCode">Key </span>
            <span
              *ngIf="cdsForm01.value.tableName && !cdsForm01.value.tableNameAs">{{cdsForm01.value.tableName}}.{{ tableRow.field }}</span>
            <span *ngIf="cdsForm01.value.tableNameAs">{{cdsForm01.value.tableNameAs}}.{{ tableRow.field }}</span>
            <span *ngIf="tableRow.aggfunctions"> )</span>
            <span *ngIf="tableRow.fieldas"><span class="keyCode"> as </span>{{ tableRow.fieldas }}</span>
            <span *ngIf="tableRow.field && !isLast">,</span>
          </span>
          <span *ngIf="tableRow.numfunctions && tableRow.field">
            <span *ngIf="tableRow.numfunctions"><span class="keyCode">{{tableRow.numfunctions}}</span>( </span>
            <span *ngIf="tableRow.key" class="keyCode">Key </span>
            <!-- <span *ngIf="cdsForm01.value.tableName && !cdsForm01.value.tableNameAs">{{cdsForm01.value.tableName}}.{{ tableRow.field }}</span>
            <span *ngIf="cdsForm01.value.tableNameAs">{{cdsForm01.value.tableNameAs}}.{{ tableRow.field }}</span> -->
            <span>{{ tableRow.field }}</span>
            <span *ngIf="tableRow.numfunctions"> )</span>
            <span *ngIf="tableRow.fieldas"><span class="keyCode"> as </span>{{ tableRow.fieldas }}</span>
            <span *ngIf="tableRow.field && !isLast">,</span>
          </span>
          <span *ngIf="!tableRow.aggfunctions && tableRow.field && !tableRow.numfunctions">
            <span *ngIf="tableRow.key" class="keyCode">Key </span>
            <span
              *ngIf="cdsForm01.value.tableName && !cdsForm01.value.tableNameAs">{{cdsForm01.value.tableName}}.{{ tableRow.field }}</span>
            <span *ngIf="cdsForm01.value.tableNameAs">{{cdsForm01.value.tableNameAs}}.{{ tableRow.field }}</span>
            <span *ngIf="tableRow.fieldas"><span class="keyCode"> as </span>{{ tableRow.fieldas }}</span>
            <span *ngIf="tableRow.field && !isLast">,</span>
            <span *ngIf="cdsStringFunctions">,</span>
          </span>
        </p>
        <!-- String Functions from Child         -->
        <p class="tab2" *ngFor="let tableRow of cdsStringFunctionsArr; let i = index; first as isFirst; last as isLast">
          <span *ngIf="tableRow.function">
            <span class="keyCode">{{tableRow.function}} </span>
            ( {{tableRow.argument1}}, {{tableRow.argument2}} )
            <span class="keyCode" *ngIf="tableRow.function"> as </span> {{tableRow.argument3}}
            <span *ngIf="cdsStringFunctions && !isLast">,</span>
          </span>
        </p>
        <!-- Begin of Session Variables Display         -->
        <span *ngIf="cdsSessionVariables">
          <p class="tab2"
            *ngFor="let tableRow of sessionVariablesForm.value.sessionVariablesArr; let i = index; first as isFirst; last as isLast">
            <span *ngIf="tableRow.name"><span class="keyCode">$session.{{tableRow.name}} as
              </span>{{tableRow.fieldas}}</span>
            <span *ngIf="tableRow.name && !isLast">,</span>
          </p>
        </span>
        <!-- End of Session Variables Display         -->
        <p *ngIf="cdsFields  || cdsStringFunctions" class="tab1">&#125;</p>
        <!-- Closing Tag of Table Fields -->
        <!-- Adding Group By        -->
        <p *ngIf="cdsGroupBy" class="tab1">
          <span *ngFor="let tableRow of tableForm.value.tableArr; let i = index; first as isFirst; last as isLast">
            <span *ngIf="tableRow.key">
              <span *ngIf="isFirst" class="keyCode">group by </span>
              <span
                *ngIf="cdsForm01.value.tableName && !cdsForm01.value.tableNameAs">{{cdsForm01.value.tableName}}.{{ tableRow.field }}</span>
              <span *ngIf="cdsForm01.value.tableNameAs">{{cdsForm01.value.tableNameAs}}.{{ tableRow.field }}</span>
            </span>
            <span *ngIf="tableRow.key && !isLast">,</span>
          </span>
        </p>
        <p>
          <span *ngIf="cdsWhere">
            <span class="keyCode">Where </span>
          </span>
          <span *ngFor="let tableRow of whereForm.value.whereArr; let i = index; first as isFirst; last as isLast">
            <span *ngIf="tableRow.wfield">{{tableRow.wfield}}</span>
            <span *ngIf="tableRow.operator"><span class="keyCode"> {{tableRow.operator}} </span></span>
            <span *ngIf="tableRow.fvalue"> '{{tableRow.fvalue}}' </span>
            <span *ngIf="tableRow.condopr"><span class="keyCode"> {{tableRow.condopr}} </span></span>
            <span *ngIf="tableRow.wfield && isLast">.</span>
            <p></p>
          </span>
        </p>
      </div>
      <div class="copyButtonPageCorner">
        <button mat-raised-button color="accent" class="copyButtonCorner" aria-label="Copy"
          (click)="copyCode(textarea)">
          Copy
        </button>
      </div>
    </div>
  </div>
</div>
