<form [formGroup]="parentForm">
  <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px"
    fxLayoutGap.xs="10px" class="padd10px">
    <mat-checkbox matTooltip="Enable Session Variables" formControlName="withStringFunctions">
      String Functions
    </mat-checkbox>
  </div>
  <div *ngIf="parentForm.value.withStringFunctions">
    <form [formGroup]="tableForm">
      <mat-table [dataSource]="tableDataSource" formArrayName="tableArr">
        <ng-container matColumnDef="function">
          <mat-header-cell *matHeaderCellDef> Functions </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i" class="dropdown">
            <mat-select formControlName="function" class="selectSmall" (selectionChange)="aggfunctionselect($event, i)">
              <mat-option value="CONCAT">Concatenation</mat-option>              
              <mat-option value="LEFT">Left</mat-option>
              <mat-option value="RIGHT">Right</mat-option>
              <mat-option value="LENGTH">Length</mat-option>
              <mat-option value="LOWER">Lower</mat-option>
              <mat-option value="UPPER">Upper</mat-option>
              <mat-option value="INSTR"></mat-option>
              <mat-option value="LPAD">Left Padding</mat-option>
              <mat-option value="RPAD">Right Padding</mat-option>
              <mat-option value="LTRIM">Left Trim</mat-option>
              <mat-option value="RTRIM">Right Trim</mat-option>
              <mat-option value="REPLACE">Replace</mat-option>
            </mat-select>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="argument1">
          <mat-header-cell *matHeaderCellDef> Argument1 </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
            <input matInput type="text" placeholder="Type here.." formControlName="argument1">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="argument2">
          <mat-header-cell *matHeaderCellDef> Argument2 </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
            <input matInput type="text" placeholder="Type here.." formControlName="argument2">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="argument3">
          <mat-header-cell *matHeaderCellDef> As </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
            <input matInput type="text" placeholder="Type here.." formControlName="argument3">
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="buttons">
          <mat-header-cell *matHeaderCellDef>
            <button mat-icon-button color="accent" aria-label="Add Rows" (click)="addRow()">
              <mat-icon>add_circle</mat-icon>
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index;" [formGroupName]="i">
            <button mat-icon-button color="warn" aria-label="Remove Rows" (click)="removeRow(i)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
        <mat-row *matRowDef="let tableRow; let i = index; columns: tableColumns;"></mat-row>
      </mat-table>
      <div class="padd10px">
        <div>{{SelectedVar}}</div>
        <div> {{instruction1}} </div>
        <div> {{instruction2}} </div>
        <div> {{instruction3}} </div>
      </div>
    </form>
  </div>
</form>
