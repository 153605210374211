<div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="error-template">
          <h1>Coming Soon</h1>          
          <div class="error-actions">
            <button mat-stroked-button color="primary" routerLink="">
              <mat-icon class="iconTextAlign">home</mat-icon>Home
            </button>
            <span style="padding-right: 5px;"></span>
            <!-- <button mat-stroked-button color="accent" (click)="email()">
              <mat-icon class="iconTextAlign">mail</mat-icon>Contact Support
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>