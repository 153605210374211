  <div class="container">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap="10px"
      fxLayoutGap.xs="10px" class="footer">
      <div class="footerContainer">
        s4hana.in is not affiliated with SAP AG or any of the SAP group of companies.<br>
        For any questions email us at s4hana.in@gmail.com
      </div>
      <button mat-button class="floatShare" (click)="showShare()">
        <mat-icon style="padding-right: 5px;">share</mat-icon>Share
      </button>
      <button mat-button class="floatShare" (click)="email()">
        <mat-icon style="padding-right: 5px;">mail</mat-icon>s4hana.in@gmail.com
      </button>
    </div>
  </div>
