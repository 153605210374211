import { Component, OnInit } from '@angular/core';
import { DbService } from './../../service/google/db.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  list = [];
  constructor(private db: DbService) { }

  ngOnInit() {
    this.getVersions();
  }

  getVersions(){
  
  }

}
