import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormArray,
  FormGroup,
  Validators
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { TableData1, cdsForm01 } from '../../form/form';

@Component({
  selector: 'app-tablestrfunc',
  templateUrl: './tablestrfunc.component.html',
  styleUrls: ['./tablestrfunc.component.css']
})
export class TablestrfuncComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Output() stringFunctionArr = new EventEmitter<string>();
  instruction1: string = '';
  instruction2: string = '';
  instruction3: string = '';
  SelectedVar: string = '';
  // Table Fields Related
  tableData: TableData1[] = [
    { function: '', argument1: '', argument2: '', argument3: '' }
  ];

  tableDataSource = new BehaviorSubject<AbstractControl[]>([]);
  tableColumns: string[] = [
    'function',
    'argument1',
    'argument2',
    'argument3',
    'buttons'
  ];
  tableRows: FormArray = this.fb.array([]);
  tableForm: FormGroup = this.fb.group({ tableArr: this.tableRows });

  constructor(private fb: FormBuilder) {
    this.parentForm = this.fb.group({
      name: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(16)])
      ],
      buffStatus: [''],
      buffType: [''],
      buffNumKeys: [''],
      compareFilter: [''],
      authorizationCheck: [''],
      label: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)])
      ],
      quickInfo: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(60)])
      ],
      defineView: ['', Validators.compose([Validators.required])],
      tableName: ['', Validators.compose([Validators.required])],
      tableNameAs: ['', Validators.compose([Validators.required])],
      withJoin: [''],
      joinTableName: ['', Validators.compose([Validators.required])],
      joinSourceField: ['', Validators.compose([Validators.required])],
      joinJoinField: ['', Validators.compose([Validators.required])],
      withParameters: [''],
      withOData: [''],
      withSessionVariables: [''],
      withStringFunctions: [''],
      withAssociation: [''],
      clientHandlingAlgorithm: [''],
      clientHandlingType: [''],
      systemField: ['']
    });
  }

  ngOnInit() {
    this.tableData.forEach((d: TableData1) => this.addRow(d, false));
    this.updateView();
    this.onChanges();
    var localTable = JSON.parse(localStorage.getItem('stringForm') || '{}');
    // const stringFuncInit = this.tableForm.controls.tableArr as FormArray;
    // stringFuncInit.clear();
    // if (
    //   Object.keys(localTable).length !== 0 &&
    //   localTable.constructor !== Object
    // ) {
    //   localTable.forEach((data: any) => {
    //     stringFuncInit.push(this.fb.group(data));
    //   });
    // }
  }

  addRow(d?: TableData1, noUpdate?: boolean) {
    const row = this.fb.group({
      function: [],
      argument1: [],
      argument2: [],
      argument3: []
    });
    this.tableRows.push(row);
    if (!noUpdate) {
      this.updateView();
    }
  }

  onChanges() {
    this.tableForm.get('tableArr')?.valueChanges.subscribe(data => {
      this.stringFunctionArr.emit(data);
      data.forEach((data: any) => {});
      if (Array.isArray(data)) {
        localStorage.setItem('stringForm', JSON.stringify(data));
      }
    });
  }
  updateView() {
    this.tableDataSource.next(this.tableRows.controls);
  }

  removeRow(index: number) {
    this.tableRows.removeAt(index);
    this.updateView();
  }

  aggfunctionselect(evt: any, i: number) {
    this.SelectedVar = '';
    this.instruction1 = '';
    this.instruction2 = '';
    this.instruction3 = '';
    let zbc = this.tableForm.get('tableArr')?.value;
    this.tableForm.get('tableArr')?.patchValue(zbc);
    if (zbc[i].function == 'CONCAT') {
      this.SelectedVar = 'Selected: Concantenation';
      this.instruction1 =
        'Concatenation of Argument1, Argument2. Enter Table name in agruments as required';
    } else if (zbc[i].function == 'LENGTH') {
      this.SelectedVar = 'Selected: Length';
      this.instruction1 =
        'Argument2 is positive numeric literal greater than 0 and less than or equal to 1333.';
    } else if (zbc[i].function == 'LEFT') {
      this.SelectedVar = 'Selected: Left ( Argument1, n)';
      this.instruction1 =
        'Argument2 is positive numeric literal greater than 0 and The value of len cannot be greater than the length of arguement.';
    } else if (zbc[i].function == 'RIGHT') {
      this.SelectedVar = 'Selected: Right ( Argument1, n)';
      this.instruction1 =
        'String of the length argument2 with the argument2 right characters of argument1 (ignoring the trailing blanks). The value of argument2 cannot be greater than the length of argument1.';
    } else if (zbc[i].function == 'LOWER') {
      this.SelectedVar = 'Selected: Lower ( Argument1 )';
      this.instruction1 = 'Argument2 & Argument3 are ignored.';
      this.instruction2 =
        'String with a length of argument1, in which all upper and lowercase characters are have been converted.';
    } else if (zbc[i].function == 'UPPER') {
      this.SelectedVar = 'Selected: Upper ( Argument1 )';
      this.instruction1 = 'Argument2 & Argument3 are ignored.';
      this.instruction2 =
        'String with a length of argument1, in which all lower and uppercase characters are have been converted.';
    } else {
      this.instruction1 = '';
    }
  }
}
