import { Component, ViewChild, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormArray,
  FormGroup,
  Validators
} from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatExpansionPanel } from '@angular/material/expansion';

import { DbService } from './../../service/google/db.service';
import {
  ParameterData,
  TableData,
  TableData1,
  SessionVariablesData,
  WhereData
} from '../../reuse/form/form';

@Component({
  selector: 'app-cds751sp00',
  templateUrl: './cds751sp00.component.html',
  styleUrls: ['./cds751sp00.component.css']
})
export class Cds751sp00Component implements OnInit {
  @ViewChild(MatExpansionPanel, { static: true }) matExpansionPanelElement:
    | MatExpansionPanel
    | any;
  // Output Display
  cdsName: string | null = '';
  cdsBuffStatus: string | null = '';
  cdsBuffType: string | null = '';
  cdsBuffNumKeys: string | null = '';
  cdsLabel: string | null = '';
  cdsQuickInfo: string | null = '';
  cdsAuthCheck: string | null = '';
  cdsCompareFilter: string | null = '';
  cdsDefineView: boolean = false;
  cdsTableName: boolean = false;
  cdsWithParameters: string | null = '';
  cdsWithJoin: string | null = '';
  cdsJoinTableName: string | null = '';
  cdsJoinSourceField: string | null = '';
  joinBool: boolean = false;
  cdsWithAssociation: string | null = '';
  cdsFields: boolean = false;
  cdsGroupBy: boolean = false;
  cdsGroupByComma: boolean = false;
  cdsSessionVariables: boolean = false;
  cdsParameters: string | null = '';
  cdsWhere: boolean = false;
  cdsClientHandlingAlgorithm: string | null = '';
  cdsClientHandlingType: string | null = '';
  cdsSystemField: string | null = '';
  tableData1: TableData1[] = [
    { function: '', argument1: '', argument2: '', argument3: '' }
  ];
  cdsStringFunctionsArr: TableData1[] = [
    { function: '', argument1: '', argument2: '', argument3: '' }
  ];

  tempTableName: string | null = '';
  nameErr: string | null = ''; //Error in name
  defineViewErr: string | null = ''; //Error in define view
  tableNameErr: string | null = ''; //Error in table name
  tableNameAsErr: string | null = ''; // Error in table name as
  joinTableNameErr: string | null = ''; //Error in table name
  joinSourceFieldErr: string | null = ''; //Error in table name
  joinJoinFieldErr: string | null = ''; //Error in table name
  tablesList = [];
  fieldsList = [];
  varTable = [];
  varParameter = [];
  varWhere = [];
  varWhereTemp: string | null = '';
  varTableTemp: string | null = '';
  varParameterTemp: string | null = '';
  textData: string | null = '';
  textAreaAValue: string | null = 'Hello';
  cdsStringFunctions: boolean = false;

  cdsForm01 = this.fb.group({
    name: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(16)])
    ],
    buffStatus: [''],
    buffType: [''],
    buffNumKeys: [''],
    compareFilter: [''],
    authorizationCheck: [''],
    label: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(60)])
    ],
    quickInfo: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(60)])
    ],
    defineView: ['', Validators.compose([Validators.required])],
    tableName: ['', Validators.compose([Validators.required])],
    tableNameAs: ['', Validators.compose([Validators.required])],
    withJoin: [''],
    joinTableName: ['', Validators.compose([Validators.required])],
    joinSourceField: ['', Validators.compose([Validators.required])],
    joinJoinField: ['', Validators.compose([Validators.required])],
    withParameters: [''],
    withOData: [''],
    withSessionVariables: [''],
    withStringFunctions: [''],
    withAssociation: [''],
    clientHandlingAlgorithm: [''],
    clientHandlingType: [''],
    systemField: ['']
  });

  // Parameters Related
  parameterData: ParameterData[] = [{ name: '', type: '' }];
  parameterDataSource = new BehaviorSubject<AbstractControl[]>([]);
  parameterColumns: string[] = ['name', 'type', 'buttons'];
  parameterRows: FormArray = this.fb.array([]);
  parameterForm: FormGroup = this.fb.group({
    parameterArr: this.parameterRows
  });

  // Table Fields Related
  tableData: TableData[] = [
    { field: '', key: false, aggfunctions: '', numfunctions: '', fieldas: '' }
  ];
  tableDataSource = new BehaviorSubject<AbstractControl[]>([]);
  tableColumns: string[] = [
    'field',
    'key',
    'aggfunctions',
    'numfunctions',
    'fieldas',
    'buttons'
  ];
  tableRows: FormArray = this.fb.array([]);
  tableForm: FormGroup = this.fb.group({ tableArr: this.tableRows });

  // Session Variables Related
  sessionVariablesData: SessionVariablesData[] = [{ name: '', fieldas: '' }];
  sessionVariablesDataSource = new BehaviorSubject<AbstractControl[]>([]);
  sessionVariablesColumns: string[] = ['name', 'fieldas', 'buttons'];
  sessionVariablesRows: FormArray = this.fb.array([]);
  sessionVariablesForm: FormGroup = this.fb.group({
    sessionVariablesArr: this.sessionVariablesRows
  });

  // Where Fields Related
  wdata: WhereData[] = [{ wfield: '', operator: '', fvalue: '', condopr: '&' }];
  wdataSource = new BehaviorSubject<AbstractControl[]>([]);
  whereColumns: string[] = [
    'wfield',
    'operator',
    'fvalue',
    'condopr',
    'wbuttons'
  ];
  wrows: FormArray = this.fb.array([]);
  whereForm: FormGroup = this.fb.group({ whereArr: this.wrows });

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private db: DbService
  ) {}

  ngOnInit() {
    this.parameterData.forEach((d: ParameterData) => this.addPRow(d, false));
    this.updatePView();

    this.tableData.forEach((d: TableData) => this.addRow(d, false));
    this.updateView();

    this.sessionVariablesData.forEach((d: SessionVariablesData) =>
      this.addSessionVariablesRow(d, false)
    );
    this.updateSessionVariablesView();

    this.wdata.forEach((d: WhereData) => this.addWRow(d, false));
    this.updateWView();
    this.onChanges();
    this.setTags();
    this.setGaView();
    var localCds = JSON.parse(localStorage.getItem('cdsForm01') || '{}');
    var localTable = JSON.parse(localStorage.getItem('tableForm') || '{}');
    if (Object.keys(localCds).length !== 0 && localCds.constructor !== Object) {
      this.cdsForm01.setValue(localCds);
    }

    const tableFielsInit = this.tableForm.controls['tableArr'] as FormArray;
    tableFielsInit.clear();
    if (Object.keys(localTable).length !== 0 && localTable.constructor !== Object) {
      localTable.forEach((data: any) => {
        tableFielsInit.push(this.fb.group(data));
      });
    }
  }

  onChanges() {
    this.cdsForm01.valueChanges.subscribe(data => {
      localStorage.setItem('cdsForm01', JSON.stringify(data));
    });
    this.cdsForm01.get('name')?.valueChanges.subscribe((data: any)=> {
      if (this.hasWhiteSpace(data)) {
        this.nameErr = 'Name cannot contain space';
        this.cdsName = '';
      } else {
        this.nameErr = '';
        this.cdsName =
          '<span class="keyCode">@AbapCatalog.sqlViewName:</span>' + data + "'";
      }
    });
    this.cdsForm01.get('buffStatus')?.valueChanges.subscribe(data => {
      this.cdsBuffStatus = data;
    });
    this.cdsForm01.get('buffType')?.valueChanges.subscribe(data => {
      this.cdsBuffType = data;
    });
    this.cdsForm01.get('buffNumKeys')?.valueChanges.subscribe(data => {
      if (data) {
        this.cdsBuffNumKeys = data;
      } else {
        this.cdsBuffNumKeys = '';
      }
    });
    this.cdsForm01.get('compareFilter')?.valueChanges.subscribe(data => {
      this.cdsCompareFilter = data;
    });
    this.cdsForm01.get('authorizationCheck')?.valueChanges.subscribe(data => {
      this.cdsAuthCheck = data;
    });
    this.cdsForm01.get('label')?.valueChanges.subscribe(data => {
      this.cdsLabel = data;
    });
    this.cdsForm01.get('quickInfo')?.valueChanges.subscribe(data => {
      this.cdsQuickInfo = data;
    });
    this.cdsForm01.get('clientHandlingType')?.valueChanges.subscribe((data: any) => {
      this.cdsClientHandlingType = data;
    });
    this.cdsForm01
      .get('clientHandlingAlgorithm')
      ?.valueChanges.subscribe(data => {
        this.cdsClientHandlingAlgorithm = data;
      });

    this.cdsForm01.get('defineView')?.valueChanges.subscribe((data: any) => {
      if (this.hasWhiteSpace(data)) {
        this.defineViewErr = 'Name cannot contain space';
        this.cdsDefineView = false;
      } else {
        this.defineViewErr = '';
        this.cdsDefineView = true;
      }
    });

    this.cdsForm01.get('tableName')?.valueChanges.subscribe((data: any) => {
      this.cdsTableName = false;
      if (this.hasWhiteSpace(data)) {
        this.tableNameErr = 'Name cannot contain space';
      } else {
        this.tableNameErr = '';
        this.tempTableName = data;
        if (data) {
          this.cdsTableName = true;
        }
      }
    });

    this.cdsForm01.get('withParameters')?.valueChanges.subscribe(data => {
      if (data) {
      } else {
      }
    });

    this.cdsForm01.get('withJoin')?.valueChanges.subscribe(data => {
      if (data) {
        this.joinBool = true;
      } else {
        this.joinBool = false;
      }
    });

    this.cdsForm01.get('joinTableName')?.valueChanges.subscribe((data: any) => {
      if (this.hasWhiteSpace(data)) {
        this.joinTableNameErr = 'Name cannot contain space';
        this.cdsJoinTableName = '';
      } else {
        this.joinTableNameErr = '';
        this.cdsJoinTableName = data;
      }
    });

    this.cdsForm01.get('joinSourceField')?.valueChanges.subscribe((data: any) => {
      if (this.hasWhiteSpace(data)) {
        this.joinSourceFieldErr = 'Name cannot contain space';
        this.cdsJoinSourceField = '';
      } else {
        this.joinSourceFieldErr = '';
        this.cdsJoinSourceField = data;
      }
    });

    this.parameterForm.get('parameterArr')?.valueChanges.subscribe(data => {
      this.varParameter = [];
      this.varParameterTemp;
      // data.forEach((data: any) => {
      //   if (data.name) {
      //     this.varParameterTemp = data.name;
      //   }
      //   if (data.type) {
      //     this.varParameterTemp = this.varParameterTemp + ' : ' + data.type;
      //   }
      //   this.varParameter.push(this.varParameterTemp);
      // });
      this.cdsParameters = this.varParameter
        .toString()
        .replace(/,/g, ',\n \t\t');
    });

    this.tableForm.get('tableArr')?.valueChanges.subscribe(data => {
      if (data.length == 0) {
        this.cdsFields = false;
      }
      if (!data && data[0].field == '') {
        this.cdsFields = false;
      }
      data.forEach((data: any) => {
        if (data.field != null) {
          this.cdsFields = true;
        } else if (data.field == '' || data.field == null) {
          this.cdsFields = false;
        }
        this.cdsGroupByComma = false;
        if (data.key == true) {
          this.cdsGroupByComma = true;
        }
        if (data.aggfunctions != '') {
          this.cdsGroupBy = true;
        }
      });
      if (Array.isArray(data)) {
        localStorage.setItem('tableForm', JSON.stringify(data));
      }
    });

    this.sessionVariablesForm
      .get('sessionVariablesArr')
      ?.valueChanges.subscribe(data => {
        if (data.length == 0) {
          this.cdsSessionVariables = false;
        }
        data.forEach((data: any) => {
          if (data.name != '') {
            this.cdsSessionVariables = true;
          } else if (data.name == '') {
            this.cdsSessionVariables = false;
          }
        });
      });

    this.whereForm.get('whereArr')?.valueChanges.subscribe(data => {
      if (data.length == 0) {
        this.cdsWhere = false;
      }
      data.forEach((data: any) => {
        if (data.wfield) {
          this.cdsWhere = true;
        }
      });
    });
  }

  aggfunctionselect(evt: any, i: number) {
    let zbc = this.tableForm.get('tableArr')?.value;
    zbc[i].numfunctions = null;
    this.tableForm.get('tableArr')?.patchValue(zbc);
  }

  numfunctionselect(evt: any, i: number) {
    let zbc = this.tableForm.get('tableArr')?.value;
    zbc[i].aggfunctions = null;
    this.tableForm.get('tableArr')?.patchValue(zbc);
  }

  hasWhiteSpace(s: string) {
    return /\s/g.test(s);
  }

  copyCode(textData: any) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = textData.innerText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this._snackBar.open('Code Copied', 'Ok', {
      duration: 2000
    });
  }

  addPRow(d?: ParameterData, noUpdate?: boolean) {
    const prow = this.fb.group({
      name: [],
      type: []
    });
    this.parameterRows.push(prow);
    if (!noUpdate) {
      this.updatePView();
    }
  }

  updatePView() {
    this.parameterDataSource.next(this.parameterRows.controls);
  }

  removePRow(index: number) {
    this.parameterRows.removeAt(index);
    this.updatePView();
  }

  addRow(d?: TableData, noUpdate?: boolean) {
    const row = this.fb.group({
      field: [],
      key: [],
      aggfunctions: [],
      numfunctions: [],
      fieldas: []
    });
    this.tableRows.push(row);
    if (!noUpdate) {
      this.updateView();
    }
  }

  updateView() {
    this.tableDataSource.next(this.tableRows.controls);
  }

  removeRow(index: number) {
    this.tableRows.removeAt(index);
    this.updateView();
  }

  addSessionVariablesRow(d?: SessionVariablesData, noUpdate?: boolean) {
    const sessionVariablesrow = this.fb.group({
      name: [],
      fieldas: []
    });
    this.sessionVariablesRows.push(sessionVariablesrow);
    if (!noUpdate) {
      this.updateSessionVariablesView();
    }
  }

  updateSessionVariablesView() {
    this.sessionVariablesDataSource.next(this.sessionVariablesRows.controls);
  }

  removeSessionVariablesRow(index: number) {
    this.sessionVariablesRows.removeAt(index);
    this.updatePView();
  }

  addWRow(d?: WhereData, noUpdate?: boolean) {
    const wrow = this.fb.group({
      wfield: [],
      operator: [],
      fvalue: [],
      condopr: []
    });
    this.wrows.push(wrow);
    if (!noUpdate) {
      this.updateWView();
    }
  }

  updateWView() {
    this.wdataSource.next(this.wrows.controls);
  }

  removeWRow(index: number) {
    this.wrows.removeAt(index);
    this.updateWView();
  }

  setTags() {
    let tags = this.db.defaultTags();
    tags.pageUrl = window.location.href;
    this.db.updateMetaTag(tags);
  }

  setGaView() {
    this.db.gaPageView('cds', window.location.href);
  }

  formInitialized(name: string, form: FormGroup) {
    // this.cdsForm01.setControl(name, form);
  }

  resetCode() {
    localStorage.clear();
    localStorage['tableForm'] = [];
    this.cdsStringFunctionsArr = [];
    // this.cdsStringFunctionsArr = [
    //   { function: '', argument1: '', argument2: '', argument3: '' }
    // ];
    this.cdsForm01.reset();
    this.tableForm.reset();
    this.whereForm.reset();
    this.cdsTableName = false;
  }

  stringFunctionArr(evt: any) {
    this.cdsStringFunctionsArr = evt;
    if (this.cdsStringFunctionsArr.length > 0) {
      if (this.cdsStringFunctionsArr[0].function !== '') {
        this.cdsStringFunctions = true;
      }
    }
  }
}
