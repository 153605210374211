<mat-toolbar color="primary" class="mat-elevation-z0">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenav.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <a routerLink="">
    <mat-icon>home</mat-icon>
  </a>
  <span class="zspace"></span>
  <h2 class="mat-h2 rbot" routerLink="">S4hana</h2>
  <span class="zspace"></span>
  <button type="button" mat-icon-button (click)="showShare()" aria-label="Share Button">
    <mat-icon>share</mat-icon>
  </button>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" fullscreen>
  <mat-sidenav #sidenav class="sidenav" fixedInViewport="true" mode="over" [(opened)]="opened"
    (opened)="events.push('open!')" (closed)="events.push('close!')">
    <mat-toolbar color="primary">Menu</mat-toolbar>
    <app-sidenav></app-sidenav>
  </mat-sidenav>
  <mat-sidenav-content fxLayout="column">
    <router-outlet></router-outlet>
    <app-footer fxFlexOffset="auto"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
